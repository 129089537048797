<template>
  <b-modal
    id="PhotoReplaceModal"
    size="xl"
    hide-header-close
    ok-only
  >
  <template #modal-header>
    <h5>{{ facilityName }} - {{ detailsName }}</h5>
  </template>
    <div v-if="replacePhoto !== ''&& photoType === 'newPhotoData'">
      <b-img 
        thumbnail 
        center 
        fluid 
        :src="replacePhoto.src"
      ></b-img>
      
    </div>
    <div v-else>
      <b-img 
        thumbnail 
        center 
        fluid
        :src="imgData"
      />
    </div>
    <template
      v-if="photoType === 'newPhotoData'"
      #modal-footer
    >
      <b-col 
        class="mt-2" 
      >
        <label 
        
          class="replacePhoto"
        >
          <input 
            type="file" 
            name="replacePhoto"
            @change="onChange"
          >
            写真の差し替え
        </label>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
export default {
  props:{
    facilityName:String,
    detailsName:String,
    imgData:String,
    photoDetail:Object,
    photoID:[],
    path:String,
    photoData:[],
    photoType:String
  },
  data() {
    return {
      role:'',
      userID:'',
      loginUser:'',
      photoThumbnail:[],
      replacePhoto:'',
      message: {
        file:''
      }
    }
  },
  mounted() {
    this.role = sessionStorage.getItem('role');
    this.loginUser = sessionStorage.getItem('userName');
    this.userID = sessionStorage.getItem('userId');
  },
  methods:{
    //サムネイル画像の変更処理
    checkThumbnail(photoID) {
      this.replacePhoto = ''
      if(this.photoThumbnail.length !== 0) {
        for(let i = 0;i< this.photoThumbnail.length;i++) {
          if(this.photoThumbnail[i].photoID == photoID) {
            this.replacePhoto = this.photoThumbnail[i].thumbnail
          }
        }
      }
    },

    onChange(event) {
      this.message.file = ''
      const files = event.target.files;
      if (files.length !== 1 || files[0].type.indexOf("image") !== 0) {
        return this.message.file = '画像ファイルをアップロードしてください';
      }
      if(files[0].type != 'image/jpeg' ) {
        return this.message.file = '写真はjpegファイルでアップロードしてください'
      }
      console.log(files)
      this.uploadImage(files)
    },

    //写真アップロード
    uploadImage(files) {
      let self = this
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function(e) {
        let img = new Image();
        img.onload = () => {
          let canvas = document.createElement('canvas');
          canvas.width = 2400;
          canvas.height = 1800;
          let ctx = canvas.getContext('2d');  
          ctx.drawImage(img, 0, 0, 2400, 1800);
          ctx.canvas.toBlob((blob) => {
          const imageFile = new File([blob], files[0].name, {
            type: files[0].type,
            lastModified: Date.now()
          });
          console.log(imageFile)
            self.photoData['beforeUploadFile'] = imageFile;
            self.photoData['uploadFile'] = files[0];
          }, files[0].type, 1);

          let resizeCanvas = document.createElement('canvas');
          resizeCanvas.width = 640;
          resizeCanvas.height = 480;
          let resizeCtx = resizeCanvas.getContext('2d');  
          resizeCtx.drawImage(img, 0, 0, 640, 480);
          resizeCtx.canvas.toBlob((blob) => {
          const resizeImageFile = new File([blob], files[0].name, {
            type: files[0].type,
            lastModified: Date.now()
          });
            self.photoData['resizeUploadFile'] = resizeImageFile;
          }, files[0].type, 1);
        }
        img.src = e.target.result;
        self.photoThumbnail.push({'thumbnail':img,'photoID':self.photoID})
        // self.photoData.replacePhotoFlag = true

        // let flag = {'replacePhotoFlag':true}
        // Object.assign(self.photoData,flag)
        self.checkThumbnail(self.photoID)
        self.$emit('checkFlag',self.photoThumbnail)
      }
    },

  }
}


</script>

<style scoped>
.replacePhoto {
    padding: 10px 40px;
    color: #ffffff;
    background-color: #384878;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}


</style>