<template>
<div>
  <Header/>
  <b-container
    style="max-width:95%; padding-top:70px;"
  >
    <b-row>
      <b-col>
        <h1>施設写真承認待ちリスト</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="1"
      >
        <b-button
          class="btn btn-success text-nowrap"
          @click="approvalPut()"
        >
          施設写真更新
        </b-button>
      </b-col>
      <b-col
        md="5"
        class="text-center"
      >
        <b>
          表示件数：{{ photoDataCount }}件中{{ displayPhoto }}件
        </b>
        <paginate
          :page-count="getPhotoPaginateCount"
          :prev-text="'<'"
          :next-text="'>'"
          :click-handler="paginateClickCallbackPhoto"
          :container-class="'pagination justify-content-center'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
          :first-last-button="true"
          :first-button-text="'<<'"
          :last-button-text="'>>'"
          :force-page="currentPage"
        >
        </paginate>
      </b-col>
      <b-col
        md="2"
      >
        <select
          class="form-select"
          name="displayLimits"
          v-model="display"
        >
          <option
            v-for="displayLimit in displayLimits"
            :key="'displayLimit-' +displayLimit.id"
            :value="displayLimit.value"
          >
            {{ displayLimit.text }}
          </option>
        </select>
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          @click="changeLimit"
        >
          表示件数変更
        </b-button>
      </b-col>
    </b-row>
    <span
      class="text-danger"
      v-if="message.approvalPhoto !== ''"
    >
      {{ message.approvalPhoto }}
    </span>

    <table
      class="table table_sticky table-bordered"
    >
      <thead
        class="table-secondary"
      >
        <tr>
          <th
            scope="col"
            style="width:5%"
          >
            一括チェック<br>
            <input
              name="photoAllcheck"
              class="form-check-input"
              type="checkbox"
              @click="selectAllPhoto"
              :checked="isPhotoAllSelected"
            >
          </th>
          <th
            scope="col"
            style="width:10%"
          >
            撮影日付
            <br>次回撮影予定日<br>
          </th>
          <th
            scope="col"
            style="width:8%"
          >
            経過日数<br>
          </th>
          <th
            scope="col"
            style="width:10%"
          >
            施設名<br>施設詳細名
          </th>
          <th
            scope="col"
            style="width:10%"
          >
            施設住所
          </th>
          <th
            scope="col"
            style="width:10%"
          >
            施設区分<br>
            撮影者
          </th>
          <th
            scope="col"
            style="width:10%"
          >
            新規写真
          </th>
          <th
            scope="col"
            style="width:10%"
          >
            承認済み写真
          </th>
          <th
            scope='col'
          >
            備考<br>
            管理者コメント
          </th>
          <th
            scope="col"
            style="width:5%"
          >
            状態
            <b-button
              size="sm"
              variant="success"
              @click="changeApploval('photo')"
              :disabled="photoCheckApploval === ''"
            >
              変更
            </b-button><br>
            <b-form-select
              v-model="photoCheckApploval"

            >
              <option
                value=""
              >
                一括選択
              </option>
              <option
                class="form-select"
                v-for="approval in approvals"
                :value="approval.value"
                :key="approval.id"
              >
                {{ approval.text }}
              </option>
            </b-form-select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(photoData, index) in photoDatas"
          :key="index"
        >
          <th
            scope="row"
          >
            {{ index + 1 }}<br>
            <input
              v-if="role === '0' || userName === photoData.vcUserLName"
              name="checkPhotoApploval"
              class="form-check-input"
              type="checkbox"
              :value="photoData.inPhotoID"
              v-model="checkPhotoApploval"
            >
          </th>
          <td>
            {{ photoData.dtPhotoDate }}<br><br>
            {{ photoData.dtNextShootingDate }}
          </td>
          <td
            class="text-center"
          >
            {{ photoData.datediff }}日
          </td>
          <td>
            <router-link
              :to="{name:'FacilityDetail',
              params : {id: photoData.inFacilityID, detailID: photoData.inFacilityDetailsID}}"
            >
              {{ photoData.vcFacilityName }}
            </router-link>
            <br><br>
            {{ photoData.vcFacilityDetailName }}
          </td>
          <td>{{ photoData.vcFacilityAdd }}</td>
          <td>
            {{ photoData.vcClassificationName }}<br><br>
            {{ photoData.vcUserLName }}
          </td>
          <td>
            <b-img
              class="img_link"
              @click="showImg(photoData.vcFacilityName,photoData.vcFacilityDetailName,photoData.vcStorage,photoData,'newPhotoData')"
              style="width:100px;height:100px;"
              :src="photoData.vcStorage"
            />
          </td>
          <td>
            <b-img
              class="img_link"
              @click="showImg(photoData.vcFacilityName,photoData.vcFacilityDetailName,photoData.oldUrl,photoData,'oldPhotoData')"
              style="width:100px;height:100px;"
              :src="photoData.oldUrl"
            />
          </td>
          <td>
            {{ photoData.txNote }}
            <br><br>
            <b-form-textarea
              v-model="photoData.txAdComment"
              placeholder="差し戻し理由を入力してください"
            >
            </b-form-textarea>
          </td>
          <td>
            <b-form-select
              v-model= photoData.inApproval
            >
              <option
                v-for="approval in approvals"
                v-bind:value="approval.value"
                v-bind:key="approval.id">
                {{ approval.text }}
              </option>
            </b-form-select>
          </td>
        </tr>
      </tbody>
    </table>

    <facilityDetailModal
      :facilityDetail="facilityDetail "
      :role="role"
    />
    <PhotoReplaceModal
      ref="replaceCheck"
      :imgData="imgData"
      :facilityName="showFacilityName"
      :detailsName="showDetailsName"
      :photoDetail="photoDetail"
      :photoID="photoID"
      :path="path"
      :photoData="photoData"
      :photoType="photoType"
      @checkFlag="checkFlag"
    ></PhotoReplaceModal>

  </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import FacilityDetailModal from '../../components/FacilityDetailModal.vue'
import PhotoReplaceModal from '../../components/PhotoReplaceModal.vue.vue'
import { Apimixin } from '../../mixins/api'
import aws from 'aws-sdk'


export default {
  title: '施設写真承認待ちリスト',
  components: {
    Header,
    FacilityDetailModal,
    PhotoReplaceModal
  },
  mixins:[Apimixin],
  data() {
    return {
      role:'',
      userName:'',
      checkFacilityApploval:[],
      checkPhotoApploval:[],
      facilityDataCount:[],
      displayfacility:[],
      facilityDatas:[],
      approvalList:[],
      approvalRegistLists:[],
      adminComment:[],
      currentPage : 1,
      limit: 10,
      isLoading:false,
      upIcon:'chevron-up',
      downIcon:'chevron-down',
      photoDatas:[],
      getPaginateCount:"",
      pageCount:0,
      offsetPhoto:1,
      offsetFacility:1,
      isFacilityAllSelected:false,
      isPhotoAllSelected:false,
      facilityCheckApploval:'',
      photoCheckApploval:'',
      oldFacilityData:[],
      newFacilityName:[],
      approval:[],
      display:'',
      approvals:[
        {id:0,text:'未承認',value:0},
        {id:1,text:'承認',value:1},
        {id:2,text:'差し戻し',value:2},
      ],
      showFacilityName:'',
      showDetailsName:'',
      photoDetail:{},
      photoID:'',
      imgData:'',
      path:'',
      photoData:[],
      photoType:'',
      changeThumbnail:[],
      s3:'',
      s3Bucket:'',
      s3BucketFolder:'before_resize/'
    }
  },
  created() {
    //申請中写真取得
    if(sessionStorage.getItem('photoApprovalCurrentPage') !== null
      || sessionStorage.getItem('photoApprovalLimit') !== null) {
      this.display = this.limit
      this.currentPage = Number(sessionStorage.getItem('photoApprovalCurrentPage'))
      this.display = Number(sessionStorage.getItem('photoApprovalLimit'))
      this.limit = Number(sessionStorage.getItem('photoApprovalLimit'))
      let offsetPhoto = Number(sessionStorage.getItem('photoApprovalOffset'))
      this.getApprovalPhotoList(offsetPhoto, this.limit);
    } else {
      this.display = this.limit
      sessionStorage.setItem('photoApprovalCurrentPage', this.currentPage)
      this.getPhotoLists
    }
    this.path = this.$route.path
    this.s3 = new aws.S3({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_AWS_S3_REGION
    });
    this.s3Bucket = process.env.VUE_APP_AWS_S3_BUCKET
  },
  mounted() {
    this.role = sessionStorage.getItem('role')
    this.userName = sessionStorage.getItem('userName')
  },
  methods:{
    //ページネーションクリック時現在のページ番号取得
    paginateClickCallbackPhoto(pageNum) {
      this.currentPage = Number(pageNum);
      sessionStorage.setItem('photoApprovalCurrentPage', this.currentPage)
      this.checkPhotoApploval = [];
      this.getPhotoLists;
    },
    checkFlag(thumbnail) {
      this.changeThumbnail= thumbnail
      for(let i = 0;i <this.photoDatas.length;i++) {
        for(let t = 0;t < this.changeThumbnail.length; t++) {
          if(this.photoDatas[i].inPhotoID == this.changeThumbnail[t].photoID) {
            this.photoDatas[i].replacePhotoFlag = true
            this.photoDatas[i].vcStorage = this.changeThumbnail[t].thumbnail.src
          }
        }
      }
    },

    //写真拡大表示
    showImg(vcFacilityName,vcFacilityDetailName,vcStorage,photoData,type) {
      if(vcStorage !== this.noImage) {
        if(vcFacilityName === vcFacilityDetailName) {
            vcFacilityDetailName = '代表情報'
          }
        vcStorage =vcStorage.replace(this.resizeImgURL,this.imgURL)
        this.imgData = vcStorage
        this.showFacilityName = vcFacilityName
        this.showDetailsName = vcFacilityDetailName
        this.photoDetail = {}
        this.photoData = photoData
        this.photoType = type
        this.photoID = photoData.inPhotoID

        this.$refs.replaceCheck.checkThumbnail(this.photoID)
        this.$bvModal.show('PhotoReplaceModal')
      }
    },
    //承認、未承認一括変更
    changeApploval(type) {
      if(type === 'photo') {
        for(let i = 0;i < this.photoDatas.length; i++) {
          this.photoDatas[i].inApproval = this.photoCheckApploval
        }
      }
    },

    //写真チェックボックス一括チェック
    selectAllPhoto() {
      if(this.isPhotoAllSelected) {
        this.checkPhotoApploval = []
        this.isPhotoAllSelected = false
      } else {
        this.checkPhotoApploval = []
        for(let key in this.photoDatas) {
          this.checkPhotoApploval.push(this.photoDatas[key].inPhotoID)
        }
        this.isPhotoAllSelected = true
      }
    },
    //写真差し替え
    async putReplacePhoto(approvalList,s3,s3Bucket) {
      console.log(approvalList)
      let message =[]
      let result = false
      for(let i = 0;i < approvalList.length;i++) {
        if(approvalList[i].replacePhotoFlag) {
          // var config = ''
          // if(approvalList[i].uploadFile.type == "image/jpeg") {
          //   config = {
          //     headers: {'Content-Type': 'image/jpeg'}
          //   }
          // } else if(approvalList[i].uploadFile.type == "image/jpg"){
          //   config = {
          //     headers: {'Content-Type': 'image/jpg'}
          //   }
          // }
          // await this.axios.put(
          //   '/putphoto/' + approvalList[i].fileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi,'_'),
          //   approvalList[i].uploadFile,
          //   config
          // )
          await s3.putObject({
            Bucket: s3Bucket,
            Key: this.putImgURL + approvalList[i].fileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi,'_'),
            Body: approvalList[i].uploadFile,
            ContentType:'image/jpg'
          }).promise()
          .then(response => {
            console.log(response)
            if(response.length !== 0) {
              result = true
              return result
            }else {
              result = false
              return result
            }
          }).catch(error => {
            result = false
            console.log(error)
            this.$swal({
              icon: 'error',
              html:`写真の差し替えに失敗しました`,
              title:'写真登録エラー:' + error,
              confirmButtonText:"閉じる"
            })
            return
          });
          // await this.axios.put(
          //   '/photo/' + approvalList[i].fileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi,'_'),
          //   approvalList[i].beforeUploadFile,
          //   config
          // )
          await s3.putObject({
            Bucket: s3Bucket,
            Key: this.imgURL + approvalList[i].fileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi,'_'),
            Body: approvalList[i].uploadFile,
            ContentType:'image/jpg'
          }).promise()
          .then(response => {
            console.log(response)
            if(response.length !== 0) {
              message.push("写真の差し替えが完了しました")
              result = true
              return result
            }else {
              message.push('写真のアップロードに失敗しました')
              result = false
              return result
            }
          }).catch(error => {
            result = false
            console.log(error)
            this.$swal({
              icon: 'error',
              html:`写真の差し替えに失敗しました`,
              title:'写真登録エラー:' + error,
              confirmButtonText:"閉じる"
            })
            return
          });
          // await this.axios.put(
          //   '/resizephoto/' + approvalList[i].fileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi,'_'),
          //   approvalList[i].resizeUploadFile,
          //   config
          // )
          await s3.putObject({
            Bucket: s3Bucket,
            Key: this.resizeImgURL + approvalList[i].fileName.replace(/\+|\]|\[|\?|%|#|\s+|\//gi,'_'),
            Body: approvalList[i].uploadFile,
            ContentType:'image/jpg'
          }).promise()
          .then(response => {
            console.log(response)
            if(response.length !== 0) {
              message.push("写真の差し替えが完了しました")
              result = true
              return result
            }else {
              message.push('写真のアップロードに失敗しました')
              result = false
              return result
            }
          }).catch(error => {
            result = false
            console.log(error)
            this.$swal({
              icon: 'error',
              html:`写真の差し替えに失敗しました`,
              title:'写真登録エラー:' + error,
              confirmButtonText:"閉じる"
            })
            return
          });
        } else {
          result = true
        }
      }
      if(result) {
        this.putPhotoData(approvalList)
      }
    },

    //写真更新実行
    approvalPut(){
      if(this.checkPhotoApploval.length !== 0) {
        let self =this
        var approvalList = []
        self.checkPhotoApploval.filter(function(value) {
          for(let i = 0;i < self.photoDatas.length;i++) {
            if(self.photoDatas[i].inPhotoID === value) {
              approvalList.push(self.photoDatas[i])
            }
          }
        })
        let approvalPhoto = []
        let error = []

        for(let i = 0;i < approvalList.length;i++) {
          if(approvalList[i].txAdComment === '') {
            approvalList[i].txAdComment = null
          }
          if(approvalList[i].inApproval === 2 &&
            approvalList[i].txAdComment === null ) {
              error.push('「' + approvalList[i].vcFacilityName + '」' + 'の差し戻し理由を入力してください。'+ '<br>')
            }
            if(approvalList[i].inApproval === 0 &&
              approvalList[i].txAdComment != null ||
              approvalList[i].inApproval === 1 &&
              approvalList[i].txAdComment != null) {
                error.push('「' + approvalList[i].vcFacilityName + '」' + 'の差し戻し理由が入力されています。' + '<br>')
              }
            if(error.length !== 0){
              approvalPhoto.push('「' + approvalList[i].vcFacilityName +'」' + 'の承認情報を更新しますか。' + '<br>')
            }
          }
          if(error.length !== 0) {
            this.$swal({
              icon: 'error',
              html:`${error}`,
              confirmButtonText:"閉じる"
            })
          }else {
            let approvalMessage = '承認情報の更新を行いますか？'
            for(let approval of approvalList) {
              if(approval.replacePhotoFlag) {
                approval['fileName']=approval.oldvcStorage.replace(this.putImgURL, '')
                approvalMessage = '写真を差し替えと承認状況の更新を行いますか？'
              }
            }
              this.$swal({
                title: '承認状況更新',
                html: `${approvalMessage}`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '確定',
                cancelButtonText:'キャンセル'
              }).then((result) => {
              if (result.isConfirmed) {
                this.$swal({
                  title: '処理中',
                  html: '処理終了まで画面はそのままにしてください。',
                  allowOutsideClick : false ,
                  showConfirmButton: false,
                  onBeforeOpen: () => {
                    this.$swal.showLoading()
                  }
                });
                this.putReplacePhoto(approvalList)
              }
            })
          }
      } else {
        this.$swal({
          icon: 'error',
          title:'施設をチェックボックスで選択してください。',
          confirmButtonText:"閉じる"
        })
      }
    },

    //表示件数変更
    changeLimit() {
      this.limit = this.display
      sessionStorage.setItem('photoApprovalLimit', JSON.stringify(this.limit))
      this.currentPage = 1
      this.getPhotoLists
    }

  },

  computed: {
    //申請中写真一覧取得
    getPhotoLists() {
      let offsetPhoto = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('photoApprovalOffset', offsetPhoto)
      sessionStorage.setItem('photoApprovalLimit', this.limit)
      return this.getApprovalPhotoList(offsetPhoto, this.limit);
    },

    //総ページ数の計算
    getPhotoPaginateCount() {
      if (this.photoDataCount >=1){
        return Math.ceil(this.photoDataCount / this.limit);
      }else{
        return 1
      }
    },

  },
  watch:{
    'checkPhotoApploval':function() {
      if (this.checkPhotoApploval.length !== this.photoDatas.length) {
        this.isPhotoAllSelected = false
      } else {
        this.isPhotoAllSelected = true
      }
    },
    'currentPage':function() {
      sessionStorage.setItem('photoApprovalCurrentPage', this.currentPage)
    },

  }
}
</script>
<style scoped>
.table_sticky {
    display: block;
    overflow-y: scroll;
    height: calc(75vh);
}
.table_sticky thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}
.table-color {
  background-color: crimson;
}

</style>
